.bgColorTransition {
  transition: background-color 0.5s ease-in-out !important;
}

.fontColorTransition {
  transition: color 0.5s ease-in-out;
}

.background {
  background: linear-gradient(to right, #042f6f, #0c59cf); /*linear-gradient(to right, rgba(4, 47, 111, 0.1), rgba(12, 89, 207, 0.1));*/
}

.engrave {
  box-shadow: inset -5px -5px 5px rgba(255, 255, 255, 0.45),
              inset 3px 3px 5px rgba(94,104,121, 0.3) !important;
}

.initial::before {
  position: absolute;
  content: "";
  inset: 0; /* same as { top: 0; right: 0; bottom: 0; left: 0; } */
  background: linear-gradient(to right, #042f6f, #0c59cf);
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.scrolled::before {
  position: absolute;
  content: "";
  inset: 0; /* same as { top: 0; right: 0; bottom: 0; left: 0; } */
  background: linear-gradient(to right, #042f6f, #0c59cf);
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}