.absoluteContainer {
  display: flex;
  position: absolute;
}

.leftContainer {
  left: 1rem;
}

.middleContainer {
  display: flex;
  flex-grow: 1;
  /* justify-content: center; */
  align-items: center;
}

.rightContainer {
  right: 1rem;
}
